<template>
  <div>
    <section>
      <div class="d-flex align-items-center">
        <v-chip-group mandatory show-arrows active-class="primary--text" v-model="selectedCat"
          @click="getExercisesByGroupId" class="flex-grow-1">
          <v-chip v-for="cat in categories" :key="cat.id">
            {{ cat.name }}
          </v-chip>
        </v-chip-group>

        <v-btn depressed plain tile icon :to="`/s/exercises/create`">
          <iconly name="plus" type="bold" class="pointer primary--text w-100 h-100" />
        </v-btn>
      </div>

      <div v-if="items && items.length > 0">
        <v-card class="my-2 " v-for="(exercise, index) in items" :key="index" flat hover ripple
          @click="openExerciseBottomSheet(exercise, index)">
          <b-list-group>
            <b-list-group-item class="d-flex align-items-center">
              <v-avatar color="red lighten-5" size="48">
                <span class="red--text text--lighten-1 f18">{{ index + 1 }}</span>
              </v-avatar>
              <div class="d-flex flex-column ms-4 me-0 align-items-start justify-content-center">
                <span class="f14 font-weight-bold">{{ exercise.title }}</span>
              </div>

              <v-btn :color="exercise.bookmarked ? 'primary' : 'grey darken-4'" icon outlined class="ms-auto me-0" elevation="0">
                <v-icon :class="exercise.bookmarked ? 'primary--text' : 'grey--text text--darken-4'">mdi-dots-horizontal
                </v-icon>
              </v-btn>

            </b-list-group-item>
          </b-list-group>
        </v-card>
      </div>

      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

      <infinite-loading @infinite="infiniteHandler" :identifier="selectedCat">
        <div slot="spinner">
          <!-- {{ $t('Loading...') }} -->
          <v-icon>mdi-selection-ellipse spin</v-icon>
        </div>
        <div slot="no-more">
          <!-- {{ $t('No more items') }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
        <div slot="no-results">
          <!-- {{ $t('No results found') }} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
      </infinite-loading>
    </section>

    <vue-bottom-sheet ref="exerciseBottomSheet">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <v-list rounded dense class="w-100">
          <span class="text-center mb-1 d-block f14 font-weight-bold">{{ selectedExercise.title }}</span>
          <v-list-item @click="handleExerciseAssign">
            <v-list-item-icon>
              <iconly name="adduser" type="light" class="primary--text" />
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t('Assign to clients') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExerciseBookmark">
            <v-list-item-icon>
              <iconly v-show="!selectedExercise.bookmarked" name="bookmark" type="light" class="primary--text" />
              <iconly v-show="selectedExercise.bookmarked" name="bookmark" type="bold" :class="'primary--text'" />
            </v-list-item-icon>
            <v-list-item-title tag="span" v-if="!selectedExercise.bookmarked">
              {{ $t('Add to favorites') }}
            </v-list-item-title>
            <v-list-item-title tag="span" v-else>
              {{ $t('Remove from favorites') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExercisePreview">
            <v-list-item-icon>
              <iconly name="show" type="light" class="primary--text" />
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t('Preview') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExerciseEdit">
            <v-list-item-icon>
              <iconly name="editsquare" type="light" class="primary--text" />
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t('Edit') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item @click="handleExerciseDelete">
            <v-list-item-icon>
              <iconly name="delete" type="light" class="primary--text" />
            </v-list-item-icon>
            <v-list-item-title tag="span">
              {{ $t('Delete') }}
            </v-list-item-title>
          </v-list-item>

          <v-btn block color="primary" @click="closeExerciseBottomSheet" class="mt-auto mb-2" rounded>{{ $t('Close') }}
          </v-btn>

        </v-list>
      </section>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="assignExerciseBottomSheet">
      <section class="d-flex flex-column align-items-center justify-content-center">
        <div class="w-100">
          <v-text-field class="w-100 border" solo flat :label="$t('Search...')" prepend-inner-icon="mdi-magnify"
            v-model="searchText" @keyup.enter="querySearchFollowers" hide-details></v-text-field>
        </div>
        <v-radio-group v-model="selectedClient" mandatory color="primary" column class="w-100" v-if="!followersError">
          <v-radio v-for="(client, index) in followers" :key="index" ripple :value="client" class="ltr">
            <template v-slot:label>
              <v-list-item class="rtl">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="require('@/assets/images/profile-circle.png')"
                    :src="client.profilePicture != null && client.profilePicture !== '' ? client.profilePicture : require('@/assets/images/profile.jpg')">
                  </v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="`${client.firstName} ${client.lastName}`"></v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </template>
          </v-radio>
        </v-radio-group>

        <div v-else class="my-4">
          <span>{{ followersErrorText }}</span>
        </div>

        <div class="mt-auto w-100">
          <span class="d-inline-block ms-0 me-2">{{ $t('Exercise time:') }}</span>
          <v-otp-input length="1" type="number" v-model="availablePeriod" hide-spin-buttons class="d-inline-block w-20">
          </v-otp-input>
          <span class="d-inline-block ms-2 me-0">{{ $t('Week') }}</span>
        </div>

        <div class="my-2 w-100">
          <v-btn block color="primary" @click="submitAssign" rounded>{{ $t('Submit') }}</v-btn>
        </div>
      </section>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="previewExerciseBottomSheet">
      
        <span class="text-center mb-1 d-block f14 font-weight-bold">{{ selectedExercise.title }}</span>
        <p class="text-justify" v-html="selectedExercisePreview.description"></p>
      
    </vue-bottom-sheet>

  </div>
</template>
<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      categories: [],
      selectedCat: 0,
      selectedExercise: {},
      selectedExercisePreview: {},
      selectedExercisePostition: -1,

      searchText: null,
      followers: [],
      followerPage: 1,
      followerSize: 12,
      followersError: false,
      followersErrorText: "",

      selectedClient: null,
      availablePeriod: null,

      items: [],
      page: 1,
      size: 12,
    };
  },
  methods: {
    openExerciseBottomSheet(exercise, index) {
      this.resetExerciseBottomSheet();
      this.$refs.exerciseBottomSheet.open();
      this.selectedExercise = exercise;
      this.selectedExercisePostition = index;

    },
    closeExerciseBottomSheet() {
      this.$refs.exerciseBottomSheet.close();
      this.selectedExercise = {};
      this.selectedExercisePostition = -1;
    },
    resetExerciseBottomSheet() {
      this.selectedClient = null;
      this.availablePeriod = "1";
    },
    getAllCategories() {
      apiService.getCategoryExerciseList(0, 200)
        .then((response) => {
          this.categories = response.data.content;
          var first = {
            id: -1,
            name: "همه"
          };

          this.categories.unshift(first)
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    getExercisesByGroupId(page, size) {
      apiService.getExercisesByGroupId(this.categories[this.selectedCat].id, page, size)
        .then((response) => {
          this.items = response.data.content;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    getAllExercises(page, size) {
      apiService.getAllExercises(page, size)
        .then((response) => {
          this.items = response.data.content;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    infiniteHandler($state) {
      if (this.selectedCat === 0) {
        apiService.getAllExercises(this.page, this.size)
          .then((response) => {
            if (response.data.content.length) {
              response.data.content.forEach(element => {
                this.items.push(element);
              });

              this.page += 1;
              $state.loaded();
            }
            else {
              $state.complete();
            }
          })
          .catch((err) => {
            console.log(err);
            $state.error();
          });
      }
      else {
        apiService.getExercisesByGroupId(this.categories[this.selectedCat].id, this.page, this.size)
          .then((response) => {
            if (response.data.content.length) {
              response.data.content.forEach(element => {
                this.items.push(element);
              });

              this.page += 1;
              $state.loaded();
            }
            else {
              $state.complete();
            }
          })
          .catch((err) => {
            console.log(err);
            $state.error();
          });
      }
    },
    handleExerciseAssign() {
      this.$refs.exerciseBottomSheet.close();
      if (this.selectedExercise.bookmarked) {
        this.getAllFollowers(0, 50);
      }
      else {
        this.$swal({
          icon: "warning",
          text: this.$t("Please add the exercise to your favorites before sending it to client"),
          confirmButtonText: this.$t('OK'),
        });
      }
    },
    getAllFollowers(page, size) {
      apiService.getAllFollowers(page, size)
        .then((response) => {
          if (response && response.data && response.data.content && response.data.content.length) {
            this.followersError = false;
            this.followers = response.data.content;

            setTimeout(() => {
              this.$refs.assignExerciseBottomSheet.open();
            }, 100);
          }
          else {
            this.followersError = true;
            this.followersErrorText = this.$t('You have no followers. The client must follow you to submit the exercise');
            setTimeout(() => {
              this.$refs.assignExerciseBottomSheet.open();
            }, 100);
          }
        })
        .catch(() => {
          this.followersError = true;
          this.followersErrorText = this.$t('You have no followers. The client must follow you to submit the exercise');
          setTimeout(() => {
            this.$refs.assignExerciseBottomSheet.open();
          }, 100);
        });
    },
    querySearchFollowers() {
      if (this.searchText) {
        this.followers = [];
        this.$refs.assignExerciseBottomSheet.close();
        apiService.querySearchFollowers(this.searchText)
          .then((response) => {
            if (response && response.data && response.data.length) {
              this.followersError = false;
              this.followers = response.data;
              setTimeout(() => {
                this.$refs.assignExerciseBottomSheet.open();
              }, 100);
            }
            else {
              this.followersError = true;
              this.followersErrorText = this.$t('No results found');
              setTimeout(() => {
                this.$refs.assignExerciseBottomSheet.open();
              }, 100);
            }
          })
          .catch(() => {
            this.followersError = true;
            this.followersErrorText = this.$t('Search error');
            setTimeout(() => {
              this.$refs.assignExerciseBottomSheet.open();
            }, 100);
          });
      }
      else {
        this.getAllFollowers(0, 50)
      }
    },
    submitAssign() {
      if (this.selectedClient == null || this.selectedClient === {}) {
        this.$refs.assignExerciseBottomSheet.close();
        this.$swal({
          icon: "warning",
          text: this.$t("No client selected"),
          confirmButtonText: this.$t('OK'),
        });
      }
      else {
        try {
          var ap = parseInt(this.availablePeriod)
          if (this.availablePeriod.trim() !== "" && this.availablePeriod != null && ap > 0) {
            var exerciseAssignDTO = {
              patientUsername: this.selectedClient.mobileNumber,
              exerciseId: this.selectedExercise.id,
              favoriteId: this.selectedExercise.favoriteId,
              availablePeriod: ap,
            }
            apiService.exerciseAssignToUser(exerciseAssignDTO)
              .then(() => {
                this.$refs.assignExerciseBottomSheet.close();
                this.$swal({
                  icon: "success",
                  text: this.$t('Exercise assined to client'),
                  confirmButtonText: this.$t('OK'),
                });
              })
              .catch((err) => {
                this.$refs.assignExerciseBottomSheet.close();
                this.$swal({
                  icon: "warning",
                  text: err ? err.response.data.detail : this.$t("Error"),
                  confirmButtonText: this.$t('OK'),
                });
              });
          }
          else {
            this.$refs.assignExerciseBottomSheet.close();
            this.$swal({
              icon: "warning",
              text: this.$t("Please enter the exercise time correctly and more than zero"),
              confirmButtonText: this.$t('OK'),
            })
          }
        }
        catch {
          this.$refs.assignExerciseBottomSheet.close();
          this.$swal({
            icon: "warning",
            text: this.$t("Please enter the exercise time correctly and more than zero"),
            confirmButtonText: this.$t('OK'),
          })
        }
      }
    },
    handleExerciseBookmark() {
      if (this.selectedExercise.bookmarked) {
        apiService.exerciseDeleteFromFavorite(this.selectedExercise.favoriteId)
          .then(() => {
            this.$refs.exerciseBottomSheet.close();
            this.$swal({
              icon: "success",
              text: this.$t('The exercise was removed from the favorites'),
              confirmButtonText: this.$t('OK'),
            });
            this.items[this.selectedExercisePostition].bookmarked = false;
          })
          .catch((err) => {
            this.$refs.exerciseBottomSheet.close();
            this.$swal({
              icon: "error",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
      }
      else {
        var exerciseFavoriteDTO = {
          drUsername: this.$store.getters.doctorId,
          exerciseId: this.selectedExercise.id,
          price: 0
        };
        apiService.exerciseAddToFavorite(exerciseFavoriteDTO)
          .then((response) => {
            this.$refs.exerciseBottomSheet.close();
            this.$swal({
              icon: "success",
              text: this.$t('The exercise was added to the favorites'),
              confirmButtonText: this.$t('OK'),
            });

            this.items[this.selectedExercisePostition].bookmarked = true;
            this.items[this.selectedExercisePostition].favoriteId = response.data.id;
          })
          .catch((err) => {
            this.$refs.exerciseBottomSheet.close();
            this.$swal({
              icon: "error",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
      }
    },
    handleExercisePreview() {
      this.$refs.exerciseBottomSheet.close();
      apiService.exercisePreview(this.selectedExercise.id)
        .then((response) => {
          this.$refs.previewExerciseBottomSheet.open();
          this.selectedExercisePreview = response.data;
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    handleExerciseEdit() {
      this.$refs.exerciseBottomSheet.close();
      this.$router.push(`/s/exercises/edit/${this.selectedExercise.id}`);
    },
    handleExerciseDelete() {
      this.$refs.exerciseBottomSheet.close();
      this.$swal({
        icon: "warning",
        showDenyButton: true,
        text: this.$t('Are you sure you want to delete the exercise?'),
        confirmButtonText: this.$t('Yes'),
        denyButtonText: this.$t('No'),
      })
        .then((result) => {
          if (result.isConfirmed) {
            apiService.deleteExercise(this.selectedExercise.id)
            .then(() => {
              this.getAllExercises(0, this.size);
              this.$swal({
                icon: "success",
                text: this.$t("The exercise was successfully deleted"),
                confirmButtonText: this.$t('OK'),
              });
            })
            .catch(() => {
              this.$swal({
                icon: "error",
                text: this.$t("Deleting exercise failed"),
                confirmButtonText: this.$t('OK'),
              });
            });
          }
        });
    },
  },
  beforeMount() {
    const menu = [
      {
        title: "Exercise history",
        to: "/s/exercises/history",
        click: '',
      },
    ];
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', true);
    this.$store.commit('update_threeDotsMenu', menu);
    this.$store.commit('update_notificationActive', false);
    this.$store.commit('update_toolbarTitleActive', true);
    this.getAllCategories();
    this.getAllExercises(0, this.size);
  },
  watch: {
    selectedCat: function () {
      this.page = 1;
      if (this.selectedCat === 0) {
        this.getAllExercises(0, this.size);
      }
      else {
        this.getExercisesByGroupId(0, this.size);
      }
    },
  },
};
</script>